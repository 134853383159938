@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./english-1/english-1-thin.woff2);
	font-family: 'english-1';
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./english-1/english-1-normal.woff2);
	font-family: 'english-1';
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./english-1/english-1-bold.woff2);
	font-family: 'english-1';
}

@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./english-2/english-2-thin.woff2);
	font-family: 'english-2';
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./english-2/english-2-normal.woff2);
	font-family: 'english-2';
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./english-2/english-2-bold.woff2);
	font-family: 'english-2';
}

@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./english-3/english-3-thin.woff2);
	font-family: 'english-3';
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./english-3/english-3-normal.woff2);
	font-family: 'english-3';
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./english-3/english-3-bold.woff2);
	font-family: 'english-3';
}

@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./myanmar-1/myanmar-1-thin.woff2);
	font-family: 'myanmar-1';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./myanmar-1/myanmar-1-normal.woff2);
	font-family: 'myanmar-1';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./myanmar-1/myanmar-1-bold.woff2);
	font-family: 'myanmar-1';
	unicode-range: U+1000-109F;
}

@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./myanmar-2/myanmar-2-thin.woff2);
	font-family: 'myanmar-2';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./myanmar-2/myanmar-2-normal.woff2);
	font-family: 'myanmar-2';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./myanmar-2/myanmar-2-bold.woff2);
	font-family: 'myanmar-2';
	unicode-range: U+1000-109F;
}

@font-face {
	font-style: thin;
	font-weight: 300;
	src: url(./myanmar-3/myanmar-3-thin.woff2);
	font-family: 'myanmar-3';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: normal;
	font-weight: 500;
	src: url(./myanmar-3/myanmar-3-normal.woff2);
	font-family: 'myanmar-3';
	unicode-range: U+1000-109F;
}
@font-face {
	font-style: bold;
	font-weight: 700;
	src: url(./myanmar-3/myanmar-3-bold.woff2);
	font-family: 'myanmar-3';
	unicode-range: U+1000-109F;
}
