*{
    padding: 0;
    margin: 0;
    font-family: 'myanmar-3', 'english-2';
}

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

*:focus,
*::before:focus,
*::after:focus {
  outline: none !important;
}

*::-webkit-scrollbar {
  display: none;
}

* {
  -ms-overflow-style: none;  
  scrollbar-width: none;
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
}

main {
	display: block;
}

h1 {
	font-size: 2em;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

a {
	background-color: transparent;
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	text-decoration: underline dotted;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

img {
	border-style: none;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	resize: none;
	overflow: -moz-scrollbars-none;
}

textarea::-webkit-scrollbar {
	display: none;
	-ms-overflow-style: none; 
	scrollbar-width: none; 
	overflow: -moz-scrollbars-none;
}

button,
input {
	overflow: visible;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
	background: none;
}

input[type="time"],
input[type="date"] {
    -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

button,
select {
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted red;
}

fieldset {
	padding: 0.35em 0.75em 0.625em;
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

details {
	display: block;
}

summary {
	display: list-item;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

:root{
    --primary-blue-color: #065695;
    --primary-yellow-color: #FCF4B3;
    --backdrop-color: rgba(196, 219, 255, 0.5);
	--primary-btn-color: #0090fe;
}
