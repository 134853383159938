nav{
  z-index: 99999;
}

.navbar-logo {
  padding: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
}

.navbar-logo span {
  font-size: 1.6rem;
  font-family: "english-1";
  font-weight: 600;
  letter-spacing: 0.1rem;
  color: #fff;
}

.navbar-logo span sup{
  font-size: 1rem;
  font-weight: 500;
  font-family: "english-3";
}

.navbar-logo img {
  border-radius: 50%;
}

.navbar{
  width: 100%;
  background-color: var(--primary-blue-color);
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.mobile_nav{
  width: 100%;
  background: var(--primary-blue-color);
  position: fixed;
  bottom: 0;
  z-index: 99999999;
}
#navbarSupportedContent {
  position: relative;
}
#navbarSupportedContent ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
}
#navbarSupportedContent li {
  list-style-type: none;
  float: left;
  
}
#navbarSupportedContent ul li a{
  
  text-decoration: none;
  
}
#navbarSupportedContent ul li a p {
  color: #fff;
  font-size: 16px;
  display: block;
  padding: 20px 20px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  position: relative;
}

#navbarSupportedContent ul li a svg{
  width: 25px;
  height: 25px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
}
#navbarSupportedContent > ul > li.active > a p {
  color: var(--primary-blue-color);
  background-color: transparent;
  transition: all 0.7s;
}
#navbarSupportedContent a:not(:only-child):after {
  content: "";
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 14px;
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  transition: 0.5s;
}
#navbarSupportedContent .active > a:not(:only-child):after {
  transform: rotate(90deg);
}
.hori-selector {
  display: inline-block;
  position: absolute;
  height: 100%;
  top: 0px;
  left: 0px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin-top: 9px;
  z-index: -1;
}
.hori-selector .right,
.hori-selector .left {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #fff;
  bottom: 0px;
}
.hori-selector .right {
  right: -25px;
}
.hori-selector .left {
  left: -25px;
}
.hori-selector .right:before,
.hori-selector .left:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-blue-color);
}
.hori-selector .right:before {
  bottom: 0;
  right: -25px;
}
.hori-selector .left:before {
  bottom: 0;
  left: -25px;
}

@media (max-width: 740px) {
  #navbarSupportedContent ul {
    padding: 0 25px;
  }

  .hori-selector {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    margin-top: -5px;
    z-index: -1;
  }

  .hori-selector .right,
  .hori-selector .left {
    top: 0px;
  }

  .hori-selector .right {
    right: -20px;
  }

  .hori-selector .left {
    left: -20px;
  }

  .hori-selector .right:before,
  .hori-selector .left:before {
    content: "";
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-blue-color);
    position: absolute;
  }

  .hori-selector .right:before {
    bottom: -9px;
    right: -9px;
  }

  .hori-selector .left:before {
    bottom: -9px;
    left: -9px;
  }
}
